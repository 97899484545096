import {Injectable} from "@angular/core";
import {formatDate} from "@angular/common";
import {ExcelService} from "./excel.service";

@Injectable({
    providedIn: "root",
})
export class HelperService {
    public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    private readonly currentLang: string;

    constructor(private excelService: ExcelService) {
        this.currentLang = document.documentElement.lang;
    }

    // public generateExcelFile(readings, table, filename: string) {
    //   const csvText = this.generateCSVText(readings, table);
    //   const headers = new HttpHeaders(
    //       {
    //         "Content-Type": "application/json"
    //       }
    //   );
    //
    //   return this.http.post(
    //       environment.oxusBotsUrl + "/excel/csv-to-excel",
    //       // "http://127.0.0.1:8000/excel/csv-to-excel",
    //       {csv: csvText, name: filename},
    //       {headers: headers}
    //   ).subscribe(
    //       (error: HttpErrorResponse) => console.error('Error converting file:', error)
    //   );
    // }

    // public getExcelFile(filename: string) {
    //   const headers = new HttpHeaders(
    //       {
    //         "Content-Type": "application/json"
    //       }
    //   );
    //
    //   return this.http.get(
    //       environment.oxusBotsUrl + `/excel/excel-file?filename=${filename}`,
    //       // `http://127.0.0.1:8000/excel/excel-file?filename=${filename}`,
    //       {headers: headers, responseType: 'blob'}
    //   )
    // }

    public generateCSVText(filename, readings, table) {
        let csv = "";
        const tr = table.children[0].children[0];
        for (let i = 0; i < tr.children.length; i++) {
            let header = tr.children[i].innerText;
            if (this.currentLang === "uz") {
                header = this.cyrillicToUzbekConverter(header);
            }

            csv += header + ",";
        }
        csv = csv.substring(0, csv.length - 1) + "\n";
        const tbody = table.children[1];
        for (let i = 0; i < tbody.children.length; i++) {
            let time;
            for (let j = 0; j < tbody.children[i].children.length; j++) {
                let value = tbody.children[i].children[j].innerText;
                if (j === 0) {
                    time = value;
                }
                if (value === "") {
                    value = readings.find((data) => {
                        const timeField = data.hasOwnProperty("Time") ? "Time" : "time";
                        const meteoTime = formatDate(
                            data[timeField],
                            "YYYY-MM-dd HH:mm",
                            "en",
                            this.timezone
                        );
                        return meteoTime === time;
                    });
                    if (value.hasOwnProperty("WindD")) {
                        // meteostations
                        value = value.WindD.toFixed(1);
                    } else if (value.hasOwnProperty("winddirection")) {
                        // forecast-meteo
                        value = value.winddirection.toFixed(1);
                    }
                } else {
                    if (time !== value) {
                        value = tbody.children[i].children[j].innerText.replace(
                            /[^-?\d+\.\d+]+\d?/gi,
                            ""
                        );
                    }
                }

                csv += value + ",";
            }
            csv = csv.substring(0, csv.length - 1) + "\n";
        }
        csv = csv.substring(0, csv.length - 1) + "\n";
        this.excelService.exportCSVToExcel(csv, filename);
        // const hiddenElement = document.createElement("a");
        // filename = filename.replace(" ", "_");
        // hiddenElement.href =
        //   "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(csv);
        // hiddenElement.target = "_blank";
        // hiddenElement.download = filename + ".csv";
        // hiddenElement.click();
        //
        // return csv;
    }

    public getWeekDay(dayNumber: number) {
        switch (dayNumber) {
            case 0:
                return $localize`Sunday`;
            case 1:
                return $localize`Monday`;
            case 2:
                return $localize`Tuesday`;
            case 3:
                return $localize`Wednesday`;
            case 4:
                return $localize`Thursday`;
            case 5:
                return $localize`Friday`;
            case 6:
                return $localize`Saturday`;
        }
    }

    public getMonths(monthNumber: number) {
        let month: string;
        switch (monthNumber) {
            case 0:
                month = $localize`January`;
                break;
            case 1:
                month = $localize`February`;
                break;
            case 2:
                month = $localize`March`;
                break;
            case 3:
                month = $localize`April`;
                break;
            case 4:
                month = $localize`May`;
                break;
            case 5:
                month = $localize`June`;
                break;
            case 6:
                month = $localize`July`;
                break;
            case 7:
                month = $localize`August`;
                break;
            case 8:
                month = $localize`September`;
                break;
            case 9:
                month = $localize`October`;
                break;
            case 10:
                month = $localize`November`;
                break;
            case 11:
                month = $localize`December`;
                break;
        }
        month = month[0].toLowerCase() + month.slice(1);
        return month;
    }

    public getWindDirections(degree: number): string {
        if (degree >= 0 && degree <= 22.5) {
            return "pi pi-arrow-down";
        }
        if (degree >= 22.5 && degree <= 67.5) {
            return "pi pi-arrow-down-left";
        }
        if (degree >= 67.5 && degree <= 112.5) {
            return "pi pi-arrow-left";
        }
        if (degree >= 112.5 && degree <= 157.5) {
            return "pi pi-arrow-up-left";
        }
        if (degree >= 157.5 && degree <= 202.5) {
            return "pi pi-arrow-up";
        }
        if (degree >= 202.5 && degree <= 247.5) {
            return "pi pi-arrow-up-right";
        }
        if (degree >= 247.5 && degree <= 292.5) {
            return "pi pi-arrow-right";
        }
        if (degree >= 292.5 && degree <= 337.5) {
            return "pi pi-arrow-down-right";
        }
        if (degree >= 337.5 && degree <= 360) {
            return "pi pi-arrow-down";
        }
    }

    public removeNulls(data: number[]) {
        for (let i = 0; i < data.length; i++) {
            let reading = data[i];
            if (reading === null) {
                if (i !== 0 && i !== data.length - 1) {
                    reading = Math.round((data[i - 1] + data[i + 1]) / 2);
                }
            }

            if (reading === 0 && data[i + 1] === 0) {
                reading = data[i - 1];
            }
        }
        return data;
    }

    private cyrillicToUzbekConverter(text: string): string {
        const cyrillicToLatin = {
            А: "A",
            а: "a",
            Б: "B",
            б: "b",
            В: "V",
            в: "v",
            Г: "G", // Replace with 'G' if not using the apostrophe for Ғ
            г: "g",
            Ғ: "G'", // Uncomment if using apostrophe for Ғ
            ғ: "g'",
            Д: "D",
            д: "d",
            Е: "Ye",
            е: "e",
            Ё: "Yo",
            ё: "yo",
            Ж: "J",
            ж: "j",
            З: "Z",
            з: "z",
            И: "I",
            и: "i",
            Й: "Y",
            й: "y",
            К: "K",
            к: "k",
            Қ: "Q",
            қ: "q",
            Л: "L",
            л: "l",
            М: "M",
            м: "m",
            Н: "N",
            н: "n",
            О: "O",
            о: "o",
            Ў: "O'", // Replace with 'O' if not using the apostrophe for Ў
            ў: "o'",
            П: "P",
            п: "p",
            Р: "R",
            р: "r",
            С: "S",
            с: "s",
            Т: "T",
            т: "t",
            У: "U",
            у: "u",
            Ф: "F",
            ф: "f",
            Х: "X",
            х: "x",
            Ц: "Ts",
            ц: "ts",
            Ч: "Ch",
            ч: "ch",
            Ш: "Sh",
            ш: "sh",
            Щ: "Sh",
            щ: "sh",
            Ъ: "'",
            ъ: "'",
            Ы: "I",
            ы: "i",
            Ь: "",
            ь: "",
            Э: "E",
            э: "e",
            Ю: "Yu",
            ю: "yu",
            Я: "Ya",
            я: "ya",
            Ҳ: "H", // Replace with 'X' if not using the apostrophe for Ҳ
            ҳ: "h",
            // Add more mappings for additional Cyrillic characters if needed
        };

        let result = "";
        for (let char of text) {
            const replacement = cyrillicToLatin[char];
            result += replacement ? replacement : char;
        }
        return result;
    }
}
