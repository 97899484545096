import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as HighchartsStock from "highcharts/highstock";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HC_windbarb from "highcharts/modules/windbarb";
import {GlobalQuery} from "../../../../store/global";
import {Subscription} from "rxjs";
import {MeteostationsQuery} from "../../../../store/meteostations/state";
import {HelperService} from "../../../../@core/utils/helper.service";

HC_windbarb(HighchartsStock);
HighchartsMore(HighchartsStock);

@Component({
  selector: "ngx-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("table") table: ElementRef;
  @ViewChild("chart") chartWrapper: ElementRef;
  @Output() changeDustSensor = new EventEmitter<string>();
  @Input() public chartOptions: Highcharts.Options | any = {};
  @Input() public tableData;
  @Input() public heading: string;
  @Input() public type: string;
  @Input() public isEvapotranspiration = false;
  @Input() public isAirsense = false;
  public HighchartsStock = HighchartsStock;
  public Highcharts = Highcharts;
  public chartRef;
  public flipped: boolean;
  public updateFlag = false;
  public isPageShared = false;
  public display = true;
  public coefficent = 1;
  public chart = Highcharts.Chart;
  public dustSensor = "Alpha";
  public dustSensors = [
    { name: "Alpha", caption: $localize`Alpha` },
    { name: "Rika", caption: $localize`Rika` },
    { name: "Nova", caption: $localize`Nova` },
  ];
  private refEvapBaseSerieData = null;
  private sidebar$: Subscription;

  public constructor(
    private globalQuery: GlobalQuery,
    private meteostationQuery: MeteostationsQuery,
    private helperService: HelperService
  ) {
    this.isPageShared = globalQuery.getValue().isShared;

  }

  ngOnInit() {
    this.sidebar$ = this.globalQuery
      .select("isSidebarToggled")
      .subscribe(() => {
        this.reflowChart();
      });
  }

  ngOnDestroy() {
    this.sidebar$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("tableData") ||
      changes.hasOwnProperty("chartOptions")
    ) {

      this.chartOptions = changes["chartOptions"].currentValue;
      this.tableData = changes["tableData"].currentValue;
      this.updateFlag = true;
    }
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };

  reflowChart(): void {
    const width = window.innerWidth;
    if (this.chartRef && width > 1200) {
      if (Object.keys(this.chartRef).length) {
        setTimeout(() => {
          this.chartRef.reflow();
        }, 0);
      }
    }
  }

  public onDustSensorChange(event) {
    this.changeDustSensor.emit(this.dustSensor);
  }

  public recalculateRefEvap(event) {
    const targetClass = "referenceevapotranspiration_fao";
    const targetSerie = this.chartOptions.series.find(
      (serie) => serie.className === targetClass
    );
    if (targetSerie) {
      if (!this.refEvapBaseSerieData) {
        this.refEvapBaseSerieData = [...targetSerie["data"]];
      }
      targetSerie["data"] = this.refEvapBaseSerieData.map((point) => {
        return [point[0], (Math.round(point[1] * event.value) * 10000) / 10000];
      });
      this.updateFlag = true;
    }
  }

  public exportToExcel(tableRef: ElementRef): void {
    const table = tableRef.nativeElement;
    const device = this.meteostationQuery.getValue().currentMeteostation;
    const deviceName = device.name.replace(/\s+/g, "_").replace(/[',]+/g, "");
    const serialNumber = device.serial_number;
    this.helperService.generateCSVText(
      deviceName + "_" + serialNumber,
      this.tableData,
      table
    );
    // const filename = deviceName + '_' + serialNumber + '.xlsx';
    // this.helperService.generateExcelFile(this.tableData, table, filename);
    // this.helperService.getExcelFile(filename).subscribe(
    //     (response: Blob) => {
    //       const link = document.createElement('a');
    //       link.href = window.URL.createObjectURL(response);
    //       link.setAttribute('download', filename);
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //       this.messageService.add({key: this.heading, severity: 'success', summary: $localize `Done`, detail: $localize `The file successfully downloaded`})
    //     },
    //     (error: HttpErrorResponse) => {
    //       console.error('Error converting file:', error);
    //       this.messageService.add({key: this.heading, severity: 'error', summary: $localize `Error`, detail: $localize `An error occured`})
    //     }
    // );
  }
}
