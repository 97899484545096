<p-toast position="top-right" key="{{heading}}"></p-toast>
<nb-flip-card class="w-100" *ngIf="display" [showToggleButton]="false" [flipped]="flipped"
              [ngClass]="{shared: isPageShared, heatmap: type === 'heatmap'}">
    <nb-card-front class="w-100">
        <nb-card class="my-1 my-md-2" status="basic">
            <nb-card-header class="p-3">
                <div class="d-flex justify-content-between">
                    <div>
                        <span class="title">{{ heading }}</span>
                    </div>
                    <div class="d-flex">
                        <div *ngIf="isEvapotranspiration" class="d-flex gap-2">
                            <p
                                    class="title"
                            >
                                Кс-коеф.
                            </p>
                            <p-inputNumber [(ngModel)]="coefficent"
                                           [showButtons]="true"
                                           size="8"
                                           inputId="ev-coefficient"
                                           id="ev-coefficient"
                                           [min]="0"
                                           [max]="100"
                                           (onInput)="recalculateRefEvap($event)"
                                           [minFractionDigits]="2">
                            </p-inputNumber>
                        </div>
                        <div *ngIf="isAirsense" class="me-2">
                            <select style="max-height: 24px; font-size: 14px"
                                    class="py-0 ps-2 pe-5 form-select"
                                    name="parameter"
                                    [(ngModel)]="dustSensor"
                                    (change)="onDustSensorChange(dustSensor)"
                                    (click)="$event.stopPropagation()">
                                <option *ngFor="let sensor of dustSensors"
                                        value="{{sensor.name}}">{{sensor.caption}}</option>
                            </select>
                        </div>
                        <div *ngIf="!isPageShared">
                            <a [style]="{cursor: 'pointer'}" (click)="flipped = !flipped"
                               class="d-flex align-items-center text-decoration-none text-dark">
                                <span class="d-inline-block mx-2" i18n>To table</span>
                                <i class="pi pi-table"></i>
                                <nb-icon icon="chevron-right-outline" pack="eva" class="flip-icon"></nb-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body class="p-2">
                <div [ngSwitch]="type" class="d-flex justify-content-center w-100">
                    <div *ngSwitchDefault class="w-100">
                        <div class="d-flex justify-content-center w-100">
                            <highcharts-chart
                                #chart
                                [style]="{width: '100%'}"
                                [constructorType]="'stockChart'"
                                [Highcharts]="HighchartsStock"
                                [(update)]="updateFlag"
                                [callbackFunction]="chartCallback"
                                [oneToOne]="true"
                                [options]="chartOptions"
                                [runOutsideAngular]="true"></highcharts-chart>
                        </div>
                    </div>
                    <div *ngSwitchCase="'wind-rose'" class="w-100">
                        <div class="d-flex justify-content-center w-100">
                            <highcharts-chart
                                #chart
                                [(update)]="updateFlag"
                                [Highcharts]="HighchartsStock"
                                [callbackFunction]="chartCallback"
                                [style]="{width: '100%'}"
                                [runOutsideAngular]="true"
                                [oneToOne]="true"
                                [options]="chartOptions"></highcharts-chart>
                        </div>
                    </div>
                    <div *ngSwitchCase="'heatmap'" class="w-100">
                        <div class="d-flex justify-content-center w-100 heatmap">
                            <highcharts-chart
                                #chart
                                [style]="{width: '100%'}"
                                [Highcharts]="Highcharts"
                                [update]="updateFlag"
                                [callbackFunction]="chartCallback"
                                [oneToOne]="true"
                                [runOutsideAngular]="true"
                                [options]="chartOptions"></highcharts-chart>
                        </div>
                    </div>
                    <div *ngSwitchCase="'area'" class="w-100">
                        <div class="d-flex justify-content-center w-100">
                            <highcharts-chart
                                #chart
                                [style]="{width: '100%'}"
                                [Highcharts]="Highcharts"
                                [update]="updateFlag"
                                [callbackFunction]="chartCallback"
                                [oneToOne]="true"
                                [runOutsideAngular]="true"
                                [options]="chartOptions"></highcharts-chart>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </nb-card-front>
    <nb-card-back class="w-100">
        <nb-card class="w-100 my-1 my-md-2" status="basic">
            <nb-card-header class="p-3">
                <div class="d-flex justify-content-between">
                    <div>
                        <span class="title">{{ heading }}</span>
                    </div>
                    <div>
                        <a *ngIf="!isPageShared"
                           [style]="{cursor: 'pointer'}" (click)="flipped = !flipped"
                           class="d-flex align-items-center text-decoration-none text-dark">
                            <span class="d-inline-block mx-2" i18n>To graph</span>
                            <i class="pi pi-chart-line"></i>
                            <nb-icon icon="chevron-right-outline" pack="eva" class="flip-icon"></nb-icon>
                        </a>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <div class="table-wrapper" *ngIf="flipped">
                    <cdk-virtual-scroll-viewport itemSize="0" class="example-viewport" style="height: 320px">
                        <table *ngIf="tableData.readings.length; else noData"
                               class="table table-striped graph-table mb-0"
                               #table>
                            <thead class="bg-white">
                            <tr class="sticky-top bg-white">
                                <th i18n class="time-cell">Time</th>
                                <th *ngFor="let readings of tableData.readings">{{ readings.caption }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *cdkVirtualFor="let time of tableData.times; index as i">
                                <td class="time-cell">{{ time | date: 'YYYY-MM-dd HH:mm':'GMT+5' : 'en-US' }}</td>
                                <td *ngFor="let readings of tableData.readings">
                                    <div *ngIf="readings.values[i] || readings.values[i] === 0 else noCellValue">
                                        <div *ngIf="readings.field !== 'WindD'; else windD">
                                            {{ readings.values[i] | round: readings.fraction }} {{ readings.suffix }}
                                        </div>
                                        <ng-template #windD>
                                            <ngx-wind-direction [degree]="readings.values[i]"></ngx-wind-direction>
                                        </ng-template>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <ng-template #noCellValue>-</ng-template>
                    </cdk-virtual-scroll-viewport>
                    <ng-template #noData>
                        <h3 class="text-center py-3" i18n>No data</h3>
                    </ng-template>
                </div>
                <div class="my-2">
                    <button (click)="exportToExcel(table)" class="btn btn-success py-1 px-2 excel-button me-2 ms-auto">
                        Excel
                        <nb-icon icon="download"></nb-icon>
                    </button>
                </div>
            </nb-card-body>
        </nb-card>
    </nb-card-back>
</nb-flip-card>
